import client from './client';

function buildQuery(data) {
  return Object.keys(data).map(key => {
    return `${key}=${data[key]}`
  }).join('&')
}

export default {
  getFirstLevelCategory: data => {
    return client.get('category/first-level/list?' + buildQuery(data)).then(res => res.data);
  },
  getBrandsList: data => {
    return client.get('brand/list?' + buildQuery(data)).then(res => res.data);
  },
  getProductList: data => {
    return client.get('publisher/product/find/list?' + buildQuery(data)).then(res => res.data);
  },
  getCategoryList: data => {
    return client.get('category/aggregation/list?' + buildQuery(data)).then(res => res.data);
  }
}