const isJsonString = (str) => {
  try {
    if (typeof JSON.parse(decodeURIComponent(str)) == "object") {
      return true;
    }
  } catch (e) {}
  return false;
}

export const exportFile = (url, qsObj, fileName,platform='seller') => {

  const localStoragePrefix = location.hostname;
  const userToken = localStorage.getItem(`${localStoragePrefix}_userToken`);

	let xhr = new XMLHttpRequest();
	let prefix = '';
	if (location.origin.indexOf('admaads') !== -1) {
		prefix = process.env.NODE_ENV === 'production' ? location.origin.replace('admaads', 'apiaads') : '';
	} else if(location.origin.indexOf('aads') !== -1) {
		prefix = process.env.NODE_ENV === 'production' ? location.origin.replace('aads', 'apiaads') : '';
	} else if(location.origin.indexOf('adm') !== -1) {
		prefix = process.env.NODE_ENV === 'production' ? location.origin.replace('adm', 'api') : '';
	} else {
		prefix = process.env.NODE_ENV === 'production' ? location.origin.replace('www', 'api') : '';
	}
	// prefix = process.env.NODE_ENV === 'production' ? location.origin.replace('www', 'api') : '';
	let reqUrl = `${prefix}/api/artemisads/v1/${url}`
  if(platform=='operator'){
    reqUrl=`${prefix}/admin/artemisads/v1/${url}`
  }
  const queryString = Object.keys(qsObj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(qsObj[key])}`).join('&');
  xhr.open('GET', `${reqUrl}?${queryString}`, true);
  xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
  switch(platform){
    case 'seller':
      xhr.setRequestHeader('x-aa-authorization', `Bearer ${userToken}`);
      break;
    case 'operator':
      xhr.setRequestHeader('x-aa-admin-authorization', `Bearer ${userToken}`);
      break;
  }
	
	xhr.responseType = 'arraybuffer';
	xhr.onreadystatechange = function() {
		if (this.readyState == this.DONE) {
			if (this.status == 200) {        
				let uint8 = new Uint8Array(this.response) // 提取uint8Array
				// 防止数据过大超过调用堆栈大小，进行分片处理
				let uint8String = '';
				const chunk = 8 * 1024;
				const groupNum = Math.ceil(
				  uint8.length / chunk
				);
				for (let i = 0; i < groupNum; i++) {
				  const arr = uint8.subarray(
					i * chunk,
					(i + 1) * chunk
				  );
				  uint8String += String.fromCharCode(...arr);
				}
				let resToString = decodeURIComponent(
				  escape(uint8String).replace(/%/g, '%25')
				);
				let disposition = this.getResponseHeader('Content-Disposition');
				if (!fileName && disposition && /filename="([^"]+)/.test(disposition)) {
					fileName = iconv.decode(RegExp.$1, 'utf-8');
				}
				if (isJsonString(resToString)) {
					let resObj = JSON.parse(decodeURIComponent(resToString));
				} else {
					let type = xhr.getResponseHeader('Content-Type');
					let blob = new Blob([this.response], { type: type });
					if (window.navigator.msSaveOrOpenBlob) {
						navigator.msSaveBlob(blob, fileName);
					} else {
						var link = document.createElement('a');
						link.href = window.URL.createObjectURL(blob);
						if (fileName) {
							link.download = fileName;
						}
						link.click();
						window.URL.revokeObjectURL(link.href);
					}
				}
				if (typeof callbackFunc === 'function') {
					callbackFunc(true);
				}
			} else {
				if (typeof callbackFunc === 'function') {
					callbackFunc(false);
				}
			}
		}
	}
  xhr.send();
}