import client from './client';
import moment from 'moment';

import { imageAxios as request } from './request';

async function hashSHA256(message) {
  // 将字符串转换为 Uint8Array
  const encoder = new TextEncoder();
  const data = encoder.encode(message);

  // 调用 SubtleCrypto 的 digest 方法生成 SHA-256 哈希
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);

  // 将 ArrayBuffer 转换为十六进制字符串
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
  return hashHex;
}


export default {
  agreeToNotice: data => {
    return client.post('seller/agree-to-notice', data).then(res => res.data);
  },
  getSellerBrands: data => {
    return client.get('seller/allbrands', data).then(res => res.data);
  },
  activateBrands: data => {
    return client.post('seller/brands/activate', data).then(res => res.data);
  },
  uploadImg: async data => {//图片上传
    const ts = moment().format('x');
    const accessToken = '7uddl5';
    const biz = 'artemisads';
    const secret = await hashSHA256('madash' + ts);
    if (data.file) {
      return request.put(`https://thumbimg.artemisads.com/api/storage/v2/file/a.jpg?biz=${biz}&accessToken=${accessToken}&secret=${secret}&ts=${ts}`, data.file).then(res => res.data);
    } else {
      return request.put(`https://thumbimg.artemisads.com/api/storage/v2/file/${data.url}?biz=${biz}&accessToken=${accessToken}&secret=${secret}&ts=${ts}`).then(res => res.data);
    }
  },
  getCommissionConfig:data=>{//获取平台佣金配置比例
    return client.get('commission/config', data).then(res => res.data);
  }
}