/*
 * @Author: Gang Jiang 
 * @Date: 2024-09-14 14:59:45 
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2024-09-21 14:37:55
 * Copy from web_h5_nuxt
 */
import axios from "axios";

// 创建实例
const service = axios.create({
  timeout: 10000
})

// 暂时使用测试环境
let devPrefix = '';
let prefix = '';
if (location.origin.indexOf('aads') !== -1) {
  prefix = process.env.NODE_ENV === 'production' ? location.origin.replace('aads', 'apiaads') : devPrefix;
} else {
  prefix = process.env.NODE_ENV === 'production' ? location.origin.replace('www', 'api') : devPrefix;
}

/**
 * 添加请求拦截器
 */
service.interceptors.request.use(
  config => {
    if (config.url.indexOf('/api/storage') === -1) {
      config.url = prefix + '/api/artemisads/v1/' + config.url;
    }
    const localStoragePrefix = location.hostname;
    const userToken = localStorage.getItem(`${localStoragePrefix}_userToken`);
    config.headers['Accept-Language'] = `en-US`;
    if (config.url.match('/product/export')) {
      config.headers['responseType'] = 'blob';
    }
    if (config.url.match('/product/preCommissionCsv')) {
      config.headers['content-type'] = 'multipart/form-data';
    }
    if (userToken) {
      config.headers['x-aa-authorization'] = `Bearer ${userToken}`;
    }
    return config;
  },
  error => {
    //请求错误时做些事
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  response => {
    let code = response.data.code
    if(code == 3006 || code == 3007 || code == 3008 || code == 3009 || code==3010 ){
      localStorage.clear();
      location.href=`${location.origin}/#/sign-in`;
    }
    return response;
  }
);

export default service;
