/*
 * @Author: hao.chen
 * @Date: 2024-11-28 
 */
import axios from "axios";
import { message } from "ant-design-vue";
import router from '~/router/router'

// const router = useRouter()

// 创建实例
const service = axios.create({
  timeout: 10000
})

// 暂时使用测试环境
let devPrefix = '';
let prefix = '';
if (location.origin.indexOf('aads') !== -1) {
  prefix = process.env.NODE_ENV === 'production' ? location.origin.replace('aads', 'apiaads') : devPrefix;
} else {
  prefix = process.env.NODE_ENV === 'production' ? location.origin.replace('www', 'api') : devPrefix;
}

/**
 * 添加请求拦截器
 */
service.interceptors.request.use(
  config => {
    if (config.url.indexOf('/api/storage') === -1) {
      config.url = prefix + '/api/artemisads/v1/' + config.url;
    }
    const localStoragePrefix = location.hostname;
    const userToken = localStorage.getItem(`${localStoragePrefix}_userToken`);
    config.headers['Accept-Language'] = `en-US`;
    if (userToken) {
      config.headers['x-aa-authorization'] = `Bearer ${userToken}`;
    }
    return config;
  },
  error => {
    //请求错误时做些事
    return Promise.reject(error);
  }
);

// formdata upload
const formatRequestConfigFormData = (config) => {
    const userToken = localStorage.getItem(`${localStoragePrefix}_userToken`);
    config.headers['Authorization'] = userToken;
    return config;
};

const err = (response) => {
    if (!response) {
      // message.error('网络开小差啦');
    } else {
      const { status } = response.response;
      switch (status) {
        case 403:
          // 处理403
          break;
        case 401:
          // router.push({path: '/login', query: {redirect: location.hostname }});
          break;
        case 400:
          if (!response.config.ignoreError) {
            // 显示错误信息
          }
          break;
        case 999:
          // window.location.href = window.location.origin + '/login';
        default:
          break;
      }
    }
    return Promise.reject(response);
  };

service.interceptors.response.use(response => {
  const extendedResponse = response;
  const res_data = response.data;
  if (extendedResponse) {
    switch (extendedResponse.status) {
      case 401:
        router.push({path: '/sign-in', query: {redirect: location.hostname }});
    }
  }
  if(res_data.code === '3010') {
      console.error('Authentication token does not match');
      router.replace('/sign-in');
      return;
  }
  if(res_data.code === '3008') {
    message.error(res_data.error);
    router.replace('/index');
    return;
  }
  if (!res_data.success) {
    message.error(res_data.error || 'Error!')
  }
  return Promise.resolve(res_data);
});

const formDataAxios = axios.create({
    baseURL: 'api/',
    timeout: 20000,
    headers: {
      'Content-Type': 'multipart/form-data',
      // 'Authorization': store.state.token
    }
  });
  
formDataAxios.interceptors.request.use(formatRequestConfigFormData, err);


const imageAxios = axios.create({
  baseURL: 'api/',
  timeout: 20000,
  headers: {
    'Content-Type': 'image/jpeg',
    // 'Authorization': store.state.token
  }
});

formDataAxios.interceptors.request.use(formatRequestConfigFormData, err);

export {
    service as axios,
    formDataAxios as formDataAxios,
    imageAxios as imageAxios
};
