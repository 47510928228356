import client from '../client';

export default {
    getProductDetail: id => {
        return client.get(`publisher/product/detail/${id}`).then(res => res.data);
    },
    //没有trackLinkId时，获取商品初始化默认链接信息
    getTrackDetailByProductId: ({ productId, countryCode }) => {
        return client.get(`${productId}/tracking/link/init?countryCode=${countryCode}`).then(res => res.data);
    },
    //根据trackLindId获取链接详情信息
    getTrackDetailByTrackLindId: trackLindId => {
        return client.get(`product/tracking/link/${trackLindId}`).then(res => res.data);
    },
    //通过x-Aa-Authorization获取对应publisher 转链工具的source options下拉数据列表
    getSourceOptionsByPublisher: () => {
        return client.get(`publisher/source/list/by-publisher`).then(res => res.data);
    },
    //保存商品链接信息
    saveTrackLink: ({ product, ...configs }) => {
        return client.post(`${productId}/tracking/link/save`, configs).then(res => res.data);
    },
    //参数变化获取商品链接
    getTrackLinkByParams: ({ productId, countryCode, ...data }) => {
        return client.post(`${productId}/tracking/link/by-param?countryCode=${countryCode}`, data).then(res => res.data);
    },
    //新增source option
    addSourceOption: data => {
        return client.post(`publisher/source/save`, data).then(res => res.data);
    },
    //生成trackLink
    saveGenerateLink: (productId, countryCode, data) => {
        return client.post(`${productId}/tracking/link/save?countryCode=${countryCode}`, data).then(res => res.data)
    },
    //获取commision history数据
    getCommissionHistoryByProductId: productId => {
        return client.get(`publisher/product/commissionHistory?id=${productId}`, productId).then(res => res.data)
    }
}