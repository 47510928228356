import { axios as request } from './apiAdmin';
export default {
    login: data => {
        return request.post('auth/login', data);
    },
    signOut: data => {
        return request.post('auth/logout', data);
    },
    getPublisherList: data => {
        return request.get('publisher/list', {params: data});
    },
    modifyPublisher: data => {
        return request.put('publisher/note', data);
    },
    reviewPublisher: data => {
        return request.put('publisher/review', data);
    },
    getPublisherCount: () => {
        return request.get('publisher/count');
    },
    // 获取商家近 30 天的广告活动聚合报表
    getSellerReport: data => {
        return request.get('seller/report/campaign-agg-30d', { params: data });
    },
    getSellerList: () => {
        return request.get('seller/list');
    },
    // 管理员
    getUserList: data => {
        return request.get('users', { params: data });
    },
    addUser: data => {
        return request.post('users', data);
    },
    enableUser: data => {
        return request.post(`users/${data.id}/enable`);
    },
    updateUser: data => {
        return request.patch(`users/${data.id}`, data);
    },
    disableUser: data => {
        return request.post(`users/${data.id}/disable`);
    }
}