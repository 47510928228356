import client from './client';
import { exportFile } from './requestDownloadFile';

export default {
  getProductList: data => {
    return client.get('seller/product/list', {params:data}).then(res => res.data);
  },
  inactiveProduct: data => {
    return client.post('seller/product/inactive', data).then(res => res.data);
  },
  activeProduct: data => {
    return client.post('seller/product/active', data).then(res => res.data);
  },
  getCommissionConfig: data => {
    return client.get('commission/config', data).then(res => res.data);
  },
  getProductCommissionHistory: data => {
    return client.get('seller/product/commissionHistory', data).then(res => res.data);
  },
  setCommission: data => {
    return client.post('seller/product/commission', data).then(res => res.data);
  },
  // exportProduct: data => {
  //   return client.get('seller/product/export?' + Object.keys(data).map(key => {
  //     return `${key}=${data[key]}`
  //   }).join('&')).then(res => res.data);
  // },
  exportProduct: data => {
    return exportFile('seller/product/export', data.qs, data.fileName);
  },
  exportCommissionHistory: data => {
    return exportFile('seller/product/exportCommissionHistory', data.qs, data.fileName);
  },
  preCommissionCsv: data => {
    return client.post('seller/product/preCommissionCsv', data).then(res => res.data);
  },
  doUpdateCommissionOrStatusCsv: data => {
    return client.post('seller/product/doUpdateCommissionOrStatusCsv', data).then(res => res.data);
  },
  getProductDetail: data => {
    return client.patch(`seller/product/detail/${data}`, data).then(res => res.data);
  },
  getCommissionHistory: data => {
    return client.get(`seller/product/commissionHistory?id=${data}`, data).then(res => res.data);
  },
  getBrandswithAvailableProducts: data => {
    return client.get(`seller/brands/with-available-products`, data).then(res => res.data);
  },
}
