import client from './client';

export default {
  signIn: data => {
    return client.post('user/auth/login', data).then(res => res.data);
  },
  signUp: data => {
    return client.post('user/auth/register', data).then(res => res.data);
  },
  signOut: data => {
    return client.post('user/auth/logout', data).then(res => res.data);
  },
  emailValidate:data =>{
    return client.post('user/auth/check-email', data).then(res => res.data);
  },
  passwordEmail:data=>{
    return client.post('user/password/forgot', data).then(res => res.data);
  },
  resetPassword:data=>{
    return client.post('user/password/reset', data).then(res => res.data);
  }
}